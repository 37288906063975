import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { sharedStyles } from '../../utils/Style';

interface YoutubeDialogProps {
  videoID: string;
  open: boolean;
  onClose: () => void;
}

export const YoutubeDialog = (props: YoutubeDialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '816px',
          },
        },
      }}
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          component='iframe'
          src={`https://www.youtube.com/embed/${props.videoID}`}
          sx={{
            height: 450,
            width: 768,
          }}
        />
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          Kapat
        </Button>
      </DialogActions>
    </Dialog>
  );
};
