import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isFinite } from 'lodash';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useRecoilValue } from 'recoil';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import { Department } from '../../interfaces/Department';
import { Pagination } from '../../interfaces/Pagination';
import { SortCriteria } from '../../interfaces/SortCriteria';
import { deleteDepartmentSection } from '../../services/DepartmentSection';
import { getStoredLanguage } from '../../utils/Language';
import { compareDepartmentSections } from '../../utils/DepartmentSection';
import { getPriorityByOrder } from '../../utils/Sort';
import { sharedColors, sharedStyles } from '../../utils/Style';
import Waiting from '../Waiting';
import ConfirmDialog from '../ConfirmDialog';
import UpsertDepartmentSectionDialog from './UpsertDepartmentSectionDialog';
import DepartmentAutocomplete from '../Department/DepartmentAutocomplete';
import SortableTableCell from '../SortableTableCell';
import { Institution } from '../../interfaces/Institution';
import InstitutionAutocomplete from '../Institution/InstitutionAutocomplete';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';
import { GroupStatus } from '../../interfaces/GroupStatus';
import { PageTitle } from '../PageTitle';
import { defaultYoutubeVideos } from '../../variables/Youtube';

interface DepartmentSectionTableProps {
  departmentSections: DepartmentSection[];
  institutions: Institution[];
  departments: Department[];
  onChange: () => void;
}

const DepartmentSectionTable = (props: DepartmentSectionTableProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [searchedValue, setSearchedValue] = useState('');
  const [searchedInstitution, setSearchedInstitution] =
    useState<Institution | null>(null);
  const [searchedDepartment, setSearchedDepartment] =
    useState<Department | null>(null);
  const [clickedDepartmentSection, setClickedDepartmentSection] = useState<
    DepartmentSection | undefined
  >(undefined);
  const [showNewDepartmentSectionDialog, setShowNewDepartmentSectionDialog] =
    useState(false);
  const [departmentSectionToDelete, setDepartmentSectionToDelete] = useState<
    DepartmentSection | undefined
  >(undefined);
  const [sortCriteria, setSortCriteria] = useState<
    SortCriteria<DepartmentSection>
  >({
    fieldName: 'institutionName',
    asc: true,
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      pageNumber: 0,
    });
  }, [searchedDepartment, searchedInstitution, searchedValue]);

  const handleSearch = (e: any) => {
    setSearchedValue(e.target.value ?? '');
  };

  const handleSearchedInstitution = (
    institutionToSearch: Institution | null,
  ) => {
    setSearchedInstitution(institutionToSearch);
    setSearchedDepartment(null);
  };

  const handleUpsertDepartmentSection = () => {
    setShowNewDepartmentSectionDialog(false);
    setClickedDepartmentSection(undefined);
    props.onChange();
  };

  const handleCloseUpsertDialog = () => {
    setClickedDepartmentSection(undefined);
    setShowNewDepartmentSectionDialog(false);
  };

  const handleDeleteDepartmentSection = () => {
    if (!isFinite(departmentSectionToDelete?.ID)) {
      return;
    }

    setLoading(true);
    deleteDepartmentSection(departmentSectionToDelete!.ID!)
      .then(() => {
        toast.success(t('department_section.successful.delete'));
        setDepartmentSectionToDelete(undefined);
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleSort = (fieldName: keyof DepartmentSection) => {
    setSortCriteria({
      fieldName,
      asc: fieldName === sortCriteria.fieldName ? !sortCriteria.asc : true,
    });
  };

  const getSystemRecommendationColor = (row: DepartmentSection): string => {
    const status = row.status ?? GroupStatus.IDEAL;
    switch (status) {
      case GroupStatus.CROWDED:
        return sharedColors.gray4;
      case GroupStatus.IDEAL:
        return sharedColors.statusGreen;
      case GroupStatus.NORMAL:
        return sharedColors.statusYellow;
      case GroupStatus.PERSONNEL_SHORTAGE:
        return sharedColors.statusOrange;
      case GroupStatus.CRITICAL_PERSONNEL_SHORTAGE:
        return sharedColors.statusRed;
      default:
        return sharedColors.black;
    }
  };

  let filteredRows = props.departmentSections.filter((departmentSection) =>
    departmentSection.name
      .toLocaleLowerCase(getStoredLanguage())
      .includes(searchedValue.toLocaleLowerCase(getStoredLanguage())),
  );
  if (searchedInstitution) {
    filteredRows = filteredRows.filter(
      (departmentSection) =>
        departmentSection.institutionID === searchedInstitution.ID,
    );
  }
  if (searchedDepartment) {
    filteredRows = filteredRows.filter(
      (departmentSection) =>
        departmentSection.departmentID === searchedDepartment.ID,
    );
  }
  filteredRows = filteredRows.sort((d1, d2) =>
    compareDepartmentSections(d1, d2, sortCriteria.fieldName) < 0
      ? getPriorityByOrder(sortCriteria.asc)
      : -getPriorityByOrder(sortCriteria.asc),
  );

  const paginatedRows = filteredRows.slice(
    pagination.pageNumber * pagination.pageSize,
    (pagination.pageNumber + 1) * pagination.pageSize,
  );

  const readOnly = !hasWriteAccess(tokenData, AccessLevel.INSTITUTION);

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <Waiting open={loading} />
      <ConfirmDialog
        content={t('department_section.delete.confirm_message')}
        open={!!departmentSectionToDelete}
        onClose={() => setDepartmentSectionToDelete(undefined)}
        onConfirm={handleDeleteDepartmentSection}
        color='error'
      />
      {!readOnly && (
        <UpsertDepartmentSectionDialog
          open={showNewDepartmentSectionDialog || !!clickedDepartmentSection}
          clickedDepartmentSection={clickedDepartmentSection}
          institutions={props.institutions}
          departments={props.departments}
          onClose={handleCloseUpsertDialog}
          onUpsert={handleUpsertDepartmentSection}
        />
      )}

      <PageTitle
        title={t('department_section.title')}
        urls={[
          ...defaultYoutubeVideos,
          'https://www.youtube.com/watch?v=k548_wsDTbA',
        ]}
      />

      <Paper
        component='div'
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          mt: 1.5,
          gap: 1.5,
          p: 2.5,
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <TextField
          id='search-text-field'
          size='small'
          label={t('shared.search')}
          variant='outlined'
          value={searchedValue}
          onChange={handleSearch}
          sx={{
            width: 250,
            minWidth: 150,
            backgroundColor: sharedColors.white,
          }}
        />

        <InstitutionAutocomplete
          label={t('department_section.institution') as string}
          width={300}
          options={props.institutions}
          value={searchedInstitution}
          onChange={handleSearchedInstitution}
          backgroundColor={sharedColors.white}
        />

        <DepartmentAutocomplete
          label={t('department_section.department') as string}
          disabled={!searchedInstitution}
          options={props.departments.filter(
            (department) =>
              !!searchedInstitution &&
              department.institutionID === searchedInstitution.ID,
          )}
          value={searchedDepartment}
          onChange={setSearchedDepartment}
          backgroundColor={sharedColors.white}
        />

        <Box component='div' sx={{ flexGrow: 1 }} />

        {!readOnly && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowNewDepartmentSectionDialog(true)}
            sx={{ ...sharedStyles.buttonText }}
          >
            {t('department_section.new')}
          </Button>
        )}
      </Paper>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='name'
                onSort={() => handleSort('name')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department_section.name')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='institutionName'
                onSort={() => handleSort('institutionName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department_section.institution')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departmentName'
                onSort={() => handleSort('departmentName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department_section.department')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='personnels'
                onSort={() => handleSort('personnels')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department_section.personnel_count')}
                </Typography>
              </SortableTableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department_section.system_recommendation')}
                </Typography>
              </TableCell>
              {!readOnly && (
                <TableCell align='center'>
                  <Typography sx={sharedStyles.columnLabel}>
                    {t('shared.action')}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <TableRow
                key={row.ID}
                sx={readOnly ? undefined : sharedStyles.editableRow}
              >
                <TableCell onClick={() => setClickedDepartmentSection(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedDepartmentSection(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.institutionName}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedDepartmentSection(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.departmentName}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedDepartmentSection(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.personnels.length.toString()}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedDepartmentSection(row)}
                >
                  <CircleIcon
                    sx={{ color: getSystemRecommendationColor(row) }}
                  />
                </TableCell>
                {!readOnly && (
                  <TableCell align='center'>
                    <Button
                      onClick={() => setDepartmentSectionToDelete(row)}
                      color='secondary'
                      sx={{
                        ...sharedStyles.buttonText,
                        p: 0,
                        minWidth: 0,
                      }}
                    >
                      {t('shared.delete')}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredRows.length}
          page={pagination.pageNumber}
          onPageChange={(_, newPage) =>
            setPagination({ ...pagination, pageNumber: newPage })
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(e) =>
            setPagination({
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage={t('shared.pagination.rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('shared.pagination.from_to_count', { from, to, count })
          }
        />
      </TableContainer>
    </Box>
  );
};

export default DepartmentSectionTable;
