import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Institution } from '../../interfaces/Institution';
import { Department } from '../../interfaces/Department';
import { AccessLevel, User, UserRole } from '../../interfaces/User';
import { getAccessLevel, getDefaultUserFilters } from '../../utils/User';
import { deleteUser, getUsers } from '../../services/User';
import { sharedColors, sharedStyles } from '../../utils/Style';
import Waiting from '../Waiting';
import InstitutionAutocomplete from '../Institution/InstitutionAutocomplete';
import { hasWriteAccess } from '../../utils/TokenData';
import { tokenDataAtom } from '../../atoms/TokenData';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import UpsertUserDialog from './UpsertUserDialog';
import ConfirmDialog from '../ConfirmDialog';

interface UserTableProps {
  institutions: Institution[];
  departments: Department[];
  departmentSections: DepartmentSection[];
}

const UserTable = (props: UserTableProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [filters, setFilters] = useState(getDefaultUserFilters());
  const [users, setUsers] = useState<User[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const [clickedUser, setClickedUser] = useState<User | undefined>(undefined);
  const [userToDelete, setUserToDelete] = useState<User | undefined>(undefined);

  const refreshUsers = () => {
    if (filters.institutionID < 0) {
      setUsers([]);
      setTotalCount(0);
      return;
    }

    setLoading(true);
    getUsers(filters)
      .then((response) => {
        setUsers(response.users);
        setTotalCount(response.totalCount);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(refreshUsers, [filters]);
  useEffect(() => {
    setFilters({
      ...filters,
      pageNumber: 0,
      departmentSectionID: undefined,
      departmentID: undefined,
      institutionID: props.institutions[0]?.ID ?? -1,
    });
  }, [props.institutions]);

  const handleCloseUpsertDialog = () => {
    setClickedUser(undefined);
    setShowNewUserDialog(false);
  };

  const handleDelete = () => {
    if (!userToDelete) {
      return;
    }

    setDeleting(true);
    deleteUser(userToDelete.id)
      .then(() => {
        toast.success(t('user.successful.delete'));
        setUserToDelete(undefined);
        refreshUsers();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setDeleting(false));
  };

  const handleUpsert = () => {
    handleCloseUpsertDialog();
    refreshUsers();
  };

  const handleSelectInstitution = (institution: Institution | null) => {
    setFilters({
      ...filters,
      pageNumber: 0,
      departmentSectionID: undefined,
      departmentID: undefined,
      institutionID: institution?.ID ?? -1,
    });
  };

  const readOnly = !hasWriteAccess(tokenData, AccessLevel.INSTITUTION);

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <Waiting open={loading || deleting} />
      <ConfirmDialog
        content={t('user.delete.confirm_message')}
        open={!!userToDelete}
        onClose={() => setUserToDelete(undefined)}
        onConfirm={handleDelete}
        color='error'
      />
      {!readOnly && (
        <UpsertUserDialog
          open={showNewUserDialog || !!clickedUser}
          clickedUser={clickedUser}
          institutions={props.institutions}
          departments={props.departments}
          departmentSections={props.departmentSections}
          onClose={handleCloseUpsertDialog}
          onUpsert={handleUpsert}
        />
      )}
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {t('user.title')}
      </Typography>
      <Paper
        component='div'
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          mt: 1.5,
          gap: 1.5,
          p: 2.5,
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Box component='div' sx={{ mr: 1.5 }}>
          <InstitutionAutocomplete
            label={t('schedule.institution') as string}
            width={300}
            options={props.institutions}
            value={
              props.institutions.find(
                (institution) => institution.ID === filters.institutionID,
              ) ?? null
            }
            onChange={handleSelectInstitution}
            backgroundColor={sharedColors.white}
          />
        </Box>
        <Box component='div' sx={{ flexGrow: 1 }} />
        {!readOnly && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowNewUserDialog(true)}
            sx={{ ...sharedStyles.buttonText }}
          >
            {t('user.new')}
          </Button>
        )}
      </Paper>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.first_name')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.last_name')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.email')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.username')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.access_level.access_level')}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.access_level.write_access')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.institution')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.department')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('user.department_section')}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('shared.action')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              const accessLevel = getAccessLevel(user.roles);

              return (
                <TableRow
                  key={user.username}
                  sx={readOnly ? undefined : sharedStyles.editableRow}
                >
                  <TableCell onClick={() => setClickedUser(user)}>
                    <Typography sx={sharedStyles.tableStringField}>
                      {user.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => setClickedUser(user)}>
                    <Typography sx={sharedStyles.tableStringField}>
                      {user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => setClickedUser(user)}>
                    <Typography sx={sharedStyles.tableStringField}>
                      {user.email}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => setClickedUser(user)}>
                    <Typography sx={sharedStyles.tableStringField}>
                      {user.username}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => setClickedUser(user)}>
                    <Typography sx={sharedStyles.tableStringField}>
                      {t(`user.access_level.${accessLevel}`)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={() => setClickedUser(user)}
                    align='center'
                  >
                    {user.roles.includes(UserRole.WRITE) ? (
                      <CheckCircleIcon
                        sx={{ color: sharedColors.statusGreen }}
                      />
                    ) : (
                      <CancelIcon sx={{ color: sharedColors.statusRed }} />
                    )}
                  </TableCell>
                  <TableCell onClick={() => setClickedUser(user)}>
                    <Typography sx={sharedStyles.tableStringField}>
                      {props.institutions
                        .filter((institution) =>
                          user.institutions.includes(
                            institution.ID?.toString() ?? '',
                          ),
                        )
                        .map((institution) => institution.name)
                        .join(', ')}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => setClickedUser(user)}>
                    {accessLevel === AccessLevel.ADMIN ||
                    accessLevel === AccessLevel.INSTITUTION ? (
                      <Typography
                        sx={{
                          ...sharedStyles.tableStringField,
                          fontWeight: 'bold',
                        }}
                      >
                        {t('user.access_level.all')}
                      </Typography>
                    ) : (
                      <Typography sx={sharedStyles.tableStringField}>
                        {props.departments
                          .filter((department) =>
                            user.departments.includes(
                              department.ID?.toString() ?? '',
                            ),
                          )
                          .map((department) => department.name)
                          .join(', ')}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell onClick={() => setClickedUser(user)}>
                    {accessLevel === AccessLevel.DEPARTMENT_SECTION ? (
                      <Typography sx={sharedStyles.tableStringField}>
                        {props.departmentSections
                          .filter((section) =>
                            user.departmentSections.includes(
                              section.ID?.toString() ?? '',
                            ),
                          )
                          .map((section) => section.name)
                          .join(', ')}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          ...sharedStyles.tableStringField,
                          fontWeight: 'bold',
                        }}
                      >
                        {t('user.access_level.all')}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    <Button
                      onClick={() => setUserToDelete(user)}
                      disabled={user.username === tokenData.username}
                      color='secondary'
                      sx={{
                        ...sharedStyles.buttonText,
                        p: 0,
                        minWidth: 0,
                      }}
                    >
                      {t('shared.delete')}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          count={totalCount}
          page={filters.pageNumber}
          onPageChange={(_, newPage) =>
            setFilters({ ...filters, pageNumber: newPage })
          }
          rowsPerPage={filters.pageSize}
          onRowsPerPageChange={(e) =>
            setFilters({
              ...filters,
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage={t('shared.pagination.rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('shared.pagination.from_to_count', { from, to, count })
          }
        />
      </TableContainer>
    </Box>
  );
};

export default UserTable;
