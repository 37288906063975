import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { toast } from 'material-react-toastify';
import { Button, Paper, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { tokenDataAtom } from '../../atoms/TokenData';
import { Schedule, ScheduleStatus } from '../../interfaces/Schedule';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';
import {
  approveSchedule,
  rollbackScheduleApproval,
} from '../../services/Schedule';
import { sharedColors, sharedStyles } from '../../utils/Style';
import Waiting from '../Waiting';
import AddScheduleNotesDialog from './AddScheduleNotesDialog';

interface ScheduleApprovalRollbackCardProps {
  schedule: Schedule;
  onChange: () => void;
}

const ScheduleApprovalRollbackCard = (
  props: ScheduleApprovalRollbackCardProps,
) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [clickedAddApproval, setClickedAddApproval] = useState(false);
  const [clickedRollback, setClickedRollback] = useState(false);

  const isVisible =
    props.schedule.status === ScheduleStatus.APPROVED &&
    hasWriteAccess(tokenData, AccessLevel.DEPARTMENT);

  const handleAddApproval = (notes: string, files: File[]) => {
    setClickedAddApproval(false);
    setLoading(true);
    approveSchedule(props.schedule.ID!, notes, files)
      .then(() => {
        toast.success(t('schedule.rollback_approval.add_approval_successful'));
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleRollback = (notes: string, files: File[]) => {
    setClickedRollback(false);
    setLoading(true);
    rollbackScheduleApproval(props.schedule.ID!, notes, files)
      .then(() => {
        toast.success(t('schedule.rollback_approval.rollback_successful'));
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return isVisible ? (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        height: 'fit-content',
        padding: 1,
        mt: 1.5,
        backgroundColor: sharedColors.statusGreenLightest,
      }}
    >
      <Waiting open={loading} />

      <AddScheduleNotesDialog
        title={t('schedule.rollback_approval.rollback_plan')}
        content={t('schedule.rollback_approval.confirm_rollback')}
        open={clickedRollback}
        onClose={() => setClickedRollback(false)}
        onConfirm={handleRollback}
        required={false}
      />

      <AddScheduleNotesDialog
        title={t('schedule.rollback_approval.add_approval')}
        content={t('schedule.rollback_approval.confirm_add_approval')}
        open={clickedAddApproval}
        onClose={() => setClickedAddApproval(false)}
        onConfirm={handleAddApproval}
        required={false}
      />

      <InfoOutlinedIcon
        sx={{
          mt: 'auto',
          mb: 'auto',
          mr: 1,
          color: sharedColors.statusGreen,
        }}
      />
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '18px',
          color: sharedColors.statusGreen,
          mt: 'auto',
          mb: 'auto',
          mr: 2,
        }}
      >
        {t('schedule.rollback_approval.message')}
      </Typography>

      {hasWriteAccess(tokenData, AccessLevel.INSTITUTION) &&
        props.schedule.managerApprovals.length <
          props.schedule.requiredManagerApprovals && (
          <Button
            color='primary'
            onClick={() => setClickedAddApproval(true)}
            sx={{
              ...sharedStyles.buttonText,
              fontSize: '12px',
              fontWeight: 'bold',
              mt: 'auto',
              mb: 'auto',
            }}
          >
            {t('schedule.rollback_approval.add_approval')}
          </Button>
        )}

      <Button
        color='error'
        onClick={() => setClickedRollback(true)}
        sx={{
          ...sharedStyles.buttonText,
          fontSize: '12px',
          fontWeight: 'bold',
          mt: 'auto',
          mb: 'auto',
        }}
      >
        {t('schedule.rollback_approval.rollback')}
      </Button>
    </Paper>
  ) : (
    <></>
  );
};

export default ScheduleApprovalRollbackCard;
