import { Department, departmentFromResponse } from './Department';

export interface Institution {
  ID?: number;
  name: string;
  city: string;
  managerApprovalCount: number;
  departments: Department[];
  totalDepartments: number;
  totalDepartmentSections: number;
  totalPersonnels: number;
}

export const institutionFromResponse = (responseData: any): Institution => ({
  ID: responseData.id,
  name: responseData.name,
  city: responseData.city,
  managerApprovalCount: responseData.manager_approval_count,
  departments: responseData.departments.map((department: any) =>
    departmentFromResponse(department),
  ),
  totalDepartments: responseData.total_departments,
  totalDepartmentSections: responseData.total_department_sections,
  totalPersonnels: responseData.total_personnels,
});

export const institutionOutputTransformer = (institution: Institution) => ({
  id: institution.ID,
  name: institution.name,
  city: institution.city,
  manager_approval_count: institution.managerApprovalCount,
});
