import { DateTime } from 'luxon';
import { Schedule } from '../interfaces/Schedule';
import { getAllDatesBetweenTwoDate, isWorkday } from './Date';
import {
  PersonnelCounts,
  PersonnelCountsByDay,
} from '../interfaces/PersonnelCounts';
import { Shift } from '../interfaces/Shift';

export const getMinimumPersonnelCountsByDay = (
  schedule: Schedule,
): PersonnelCountsByDay => {
  const personnelCountsByDay: PersonnelCountsByDay = {};
  schedule.fullDayDates.forEach((fd) => {
    for (
      let d = new Date(fd.startAt.getTime());
      d <= fd.endAt;
      d.setDate(d.getDate() + 1)
    ) {
      const dateStr = DateTime.fromJSDate(d).toISODate()!;
      personnelCountsByDay[dateStr] = {
        day: fd.minimumPersonnelCount + fd.minimumSeniorPersonnelCount,
        night: fd.minimumPersonnelCount + fd.minimumSeniorPersonnelCount,
      };
    }
  });

  for (
    let d = new Date(schedule.startAt.getTime());
    d <= schedule.endAt;
    d.setDate(d.getDate() + 1)
  ) {
    const dateStr = DateTime.fromJSDate(d).toISODate()!;
    if (Object.keys(personnelCountsByDay).includes(dateStr)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (isWorkday(d)) {
      personnelCountsByDay[dateStr] = {
        day:
          (schedule.departmentSettings.min_day_shift_personnel_count ?? 0) +
          (schedule.departmentSettings.min_day_shift_senior_personnel_count ??
            0),
        night:
          (schedule.departmentSettings.min_night_shift_personnel_count ?? 0) +
          (schedule.departmentSettings.min_night_shift_senior_personnel_count ??
            0),
      };
    } else {
      personnelCountsByDay[dateStr] = {
        day:
          (schedule.departmentSettings.min_weekend_shift_personnel_count ?? 0) +
          (schedule.departmentSettings
            .min_weekend_shift_senior_personnel_count ?? 0),
        night:
          (schedule.departmentSettings.min_weekend_shift_personnel_count ?? 0) +
          (schedule.departmentSettings
            .min_weekend_shift_senior_personnel_count ?? 0),
      };
    }
  }

  return personnelCountsByDay;
};

export const getWorkingPersonnelCountsByDay = (
  schedule: Schedule,
): PersonnelCountsByDay => {
  const personnelCountsByDay: PersonnelCountsByDay = {};
  const personnelNames = Object.keys(schedule.plan);

  getAllDatesBetweenTwoDate(schedule.startAt, schedule.endAt).forEach((d) => {
    const dateStr = DateTime.fromJSDate(d).toISODate()!;
    const personnelCounts: PersonnelCounts = { day: 0, night: 0 };
    personnelNames.forEach((personnelName) => {
      const shiftsOfPersonnel = schedule.plan[personnelName] ?? {};
      const shift = shiftsOfPersonnel[dateStr];
      if (shift === Shift.ALL_DAY) {
        personnelCounts.day += 1;
        personnelCounts.night += 1;
      } else if (shift === Shift.NIGHT) {
        personnelCounts.night += 1;
      } else if (shift === Shift.DAY) {
        personnelCounts.day += 1;
      }
    });

    personnelCountsByDay[dateStr] = personnelCounts;
  });

  return personnelCountsByDay;
};
