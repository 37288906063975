import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { toast } from 'material-react-toastify';
import { useRecoilValue } from 'recoil';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import {
  Personnel,
  PersonnelPosition,
  PersonnelUpsert,
} from '../../interfaces/Personnel';
import { upsertPersonnel } from '../../services/Personnel';
import { Department } from '../../interfaces/Department';
import PersonnelPositionDropdown from './PersonnelPositionDropdown';
import {
  getPersonnelTemplate,
  isDepartmentSectionNeededForPosition,
  isOnCallAvailableForPosition,
} from '../../utils/Personnel';
import NumberInput from '../NumberInput';
import { Institution } from '../../interfaces/Institution';
import InstitutionDropdown from '../Institution/InstitutionDropdown';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';

interface UpsertPersonnelDialogProps {
  open: boolean;
  clickedPersonnel?: Personnel;
  institutions: Institution[];
  departments: Department[];
  onClose: () => void;
  onUpsert: () => void;
}

const UpsertPersonnelDialog = (props: UpsertPersonnelDialogProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState<PersonnelUpsert>(
    getPersonnelTemplate(),
  );

  useEffect(() => {
    setCurrentState(
      props.clickedPersonnel
        ? {
            ...props.clickedPersonnel,
            firstName: props.clickedPersonnel.name
              .split(' ')
              .slice(0, -1)
              .join(' '),
            lastName: props.clickedPersonnel.name.split(' ').slice(-1)[0],
          }
        : {
            ...getPersonnelTemplate(),
            institutionName: currentState.institutionName,
            institutionID: currentState.institutionID,
            departmentName: currentState.departmentName,
            departmentID: currentState.departmentID,
            departmentSectionName: currentState.departmentSectionName,
            departmentSectionID: currentState.departmentSectionID,
            position: currentState.position,
          },
    );
  }, [props.clickedPersonnel, props.open]);

  const selectedDepartment = props.departments.find(
    (department) => department.ID === currentState.departmentID,
  );

  const handleConfirm = () => {
    setLoading(true);

    upsertPersonnel(currentState)
      .then(() => {
        toast.success(
          props.clickedPersonnel
            ? t('personnel.successful.update')
            : t('personnel.successful.add'),
        );
        props.onUpsert();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangeFirstName = (e: any) => {
    setCurrentState({
      ...currentState,
      firstName: e.target.value,
      name: `${e.target.value} ${currentState.lastName}`.trim(),
    });
  };

  const handleChangeLastName = (e: any) => {
    setCurrentState({
      ...currentState,
      lastName: e.target.value,
      name: `${currentState.firstName} ${e.target.value}`.trim(),
    });
  };

  const handleChangePersonnelID = (e: any) => {
    setCurrentState({ ...currentState, personnelID: e.target.value });
  };

  const handleChangePosition = (newPosition: PersonnelPosition) => {
    setCurrentState({
      ...currentState,
      position: newPosition,
      canBeOnCall: isOnCallAvailableForPosition(newPosition)
        ? currentState.canBeOnCall
        : false,
      departmentSectionID: isDepartmentSectionNeededForPosition(newPosition)
        ? currentState.departmentSectionID
        : undefined,
      departmentSectionName: isDepartmentSectionNeededForPosition(newPosition)
        ? currentState.departmentSectionName
        : undefined,
    });
  };

  const getUpdatedStateByDepartment = (
    department: Department,
  ): PersonnelUpsert => ({
    ...currentState,
    departmentName: department.name,
    departmentID: department.ID!,
    departmentSectionID: isDepartmentSectionNeededForPosition(
      currentState.position,
    )
      ? department.departmentSections[0]?.ID
      : undefined,
    departmentSectionName: isDepartmentSectionNeededForPosition(
      currentState.position,
    )
      ? department.departmentSections[0]?.name
      : undefined,
  });

  const handleChangeInstitution = (newInstitution: Institution) => {
    const department = newInstitution.departments[0];
    setCurrentState(
      department
        ? {
            ...getUpdatedStateByDepartment(department),
            institutionID: newInstitution.ID!,
            institutionName: newInstitution.name,
          }
        : {
            ...currentState,
            institutionID: newInstitution.ID!,
            institutionName: newInstitution.name,
          },
    );
  };

  const handleChangeDepartment = (e: any) => {
    const department = props.departments.find(
      (x) => x.name === e.target.value,
    )!;

    setCurrentState(getUpdatedStateByDepartment(department));
  };

  const handleChangeDepartmentSection = (e: any) => {
    setCurrentState({
      ...currentState,
      departmentSectionName: e.target.value,
      departmentSectionID: (selectedDepartment?.departmentSections ?? []).find(
        (departmentSection) => departmentSection.name === e.target.value,
      )?.ID,
    });
  };

  const handleToggleCanBeOnCall = () => {
    setCurrentState({
      ...currentState,
      canBeOnCall: !currentState.canBeOnCall,
    });
  };

  const handleToggleIs4D = () => {
    setCurrentState({
      ...currentState,
      is4d: !currentState.is4d,
      dayShiftHours: currentState.is4d ? 8 : 7.5,
    });
  };

  const handleChangeNotes = (e: any) => {
    setCurrentState({ ...currentState, notes: e.target.value ?? '' });
  };

  const handleChangeDayShiftHours = (newHours: number) => {
    setCurrentState({ ...currentState, dayShiftHours: newHours });
  };

  const hasDepartmentalAccess = hasWriteAccess(
    tokenData,
    AccessLevel.DEPARTMENT,
  );
  const readOnly = !hasWriteAccess(tokenData, AccessLevel.DEPARTMENT_SECTION);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {props.clickedPersonnel
          ? t('personnel.dialog.update')
          : t('personnel.dialog.add')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <TextField
            label={t('personnel.name')}
            value={currentState.firstName}
            disabled={readOnly}
            onChange={handleChangeFirstName}
            size='small'
            error={isEmpty(currentState.firstName)}
            sx={{ width: 230, mr: 1.5 }}
          />
          <TextField
            label={t('personnel.surname')}
            value={currentState.lastName}
            disabled={readOnly}
            onChange={handleChangeLastName}
            size='small'
            error={isEmpty(currentState.lastName)}
            sx={{ width: 230 }}
          />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <TextField
            label={t('personnel.id')}
            value={currentState.personnelID}
            disabled={readOnly}
            onChange={handleChangePersonnelID}
            size='small'
            error={isEmpty(currentState.name)}
            type='number'
            sx={{ width: 230, mr: 1.5 }}
          />
          <PersonnelPositionDropdown
            label={t('personnel.position') as string}
            disabled={readOnly}
            required
            value={currentState.position}
            onChange={handleChangePosition}
            width={230}
          />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <InstitutionDropdown
            label={t('personnel.institution') as string}
            backgroundColor={sharedColors.white}
            options={props.institutions}
            disabled={!!props.clickedPersonnel || readOnly}
            value={currentState.institutionID}
            onChange={handleChangeInstitution}
          />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <FormControl
            disabled={readOnly}
            size='small'
            sx={{ width: 230, mr: 1.5 }}
          >
            <InputLabel>{t('personnel.department')}</InputLabel>
            <Select
              value={currentState.departmentName}
              label={t('personnel.department')}
              onChange={handleChangeDepartment}
              name='select'
              required
            >
              {props.departments
                .filter(
                  (department) =>
                    department.institutionID === currentState.institutionID,
                )
                .map((department) => (
                  <MenuItem value={department.name} key={department.ID}>
                    {department.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            disabled={
              !isDepartmentSectionNeededForPosition(currentState.position) ||
              readOnly
            }
            size='small'
            sx={{ width: 230 }}
          >
            <InputLabel>{t('personnel.department_section')}</InputLabel>
            <Select
              value={currentState.departmentSectionName ?? ''}
              label={t('personnel.department_section')}
              disabled={
                !isDepartmentSectionNeededForPosition(currentState.position) ||
                readOnly
              }
              onChange={handleChangeDepartmentSection}
              name='select'
              required
            >
              {selectedDepartment?.departmentSections.map(
                (departmentSection) => (
                  <MenuItem
                    value={departmentSection.name}
                    key={departmentSection.ID}
                  >
                    {departmentSection.name}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <TextField
            label={t('personnel.notes')}
            value={currentState.notes}
            disabled={readOnly}
            onChange={handleChangeNotes}
            size='small'
            fullWidth
            multiline
          />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <Box component='div' sx={{ width: 230, mr: 1.5 }}>
            <NumberInput
              label={t('personnel.day_shift_hours') as string}
              value={currentState.dayShiftHours}
              disabled={readOnly}
              onChange={handleChangeDayShiftHours}
              acceptFloat
              precision={2}
              endAdornment={
                <InputAdornment position='end'>
                  {currentState.is4d
                    ? `${t('shared.hours')} x6`
                    : t('shared.hours')}
                </InputAdornment>
              }
            />
          </Box>
          <FormControlLabel
            disabled={
              !isOnCallAvailableForPosition(currentState.position) || readOnly
            }
            control={
              <Checkbox
                checked={currentState.canBeOnCall}
                onChange={handleToggleCanBeOnCall}
              />
            }
            label={
              <Typography sx={sharedStyles.subtitle2}>
                {t('personnel.can_be_on_call')}
              </Typography>
            }
          />
          <FormControlLabel
            disabled={readOnly}
            control={
              <Checkbox
                checked={currentState.is4d}
                onChange={handleToggleIs4D}
              />
            }
            label={<Typography sx={sharedStyles.subtitle2}>4D</Typography>}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            isEmpty(currentState.firstName) ||
            isEmpty(currentState.position) ||
            isEmpty(currentState.departmentName) ||
            readOnly ||
            (currentState.position === PersonnelPosition.HEAD_NURSE &&
              !hasDepartmentalAccess)
          }
          onClick={handleConfirm}
          sx={sharedStyles.buttonText}
        >
          {props.clickedPersonnel
            ? t('personnel.dialog.update')
            : t('personnel.dialog.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertPersonnelDialog;
