import { PersonnelPerformance } from '../interfaces/PersonnelPerformance';
import { getStoredLanguage } from './Language';
import { getPersonnelPositionWeight } from './Personnel';

export const comparePersonnelPerformance = (
  p1: PersonnelPerformance,
  p2: PersonnelPerformance,
  fieldName: keyof PersonnelPerformance,
) => {
  const locale = getStoredLanguage();

  switch (fieldName) {
    case 'institutionID':
      return p1.institutionID - p2.institutionID;
    case 'institutionName':
      return p1.institutionName.localeCompare(p2.institutionName, locale);
    case 'departmentID':
      return p1.departmentID - p2.departmentID;
    case 'departmentSectionID':
      return (p1.departmentSectionID ?? 0) - (p2.departmentSectionID ?? 0);
    case 'departmentName':
      return p1.departmentName.localeCompare(p2.departmentName, locale);
    case 'departmentSectionName':
      return (p1.departmentSectionName ?? '').localeCompare(
        p2.departmentSectionName ?? '',
        locale,
      );
    case 'name':
      return p1.name.localeCompare(p2.name, locale);
    case 'position':
      return (
        getPersonnelPositionWeight(p1.position) -
        getPersonnelPositionWeight(p2.position)
      );
    case 'is4d': {
      if (p1.is4d && !p2.is4d) {
        return -1;
      }
      if (!p1.is4d && p2.is4d) {
        return 1;
      }

      return p2.name.localeCompare(p1.name, locale);
    }
    case 'totalOvertime':
      return p1.totalOvertime - p2.totalOvertime;
    case 'totalPtoHours':
      return p1.totalPtoHours - p2.totalPtoHours;
    default:
      return p1.name.localeCompare(p2.name, locale);
  }
};
