import { useState } from 'react';
import { DefaultTFuncReturn } from 'i18next';
import { Box, IconButton, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { isEmpty } from 'lodash';
import { sharedColors, sharedStyles } from '../utils/Style';
import { YoutubeListDrawer } from './Youtube/YoutubeListDrawer';

interface PageTitleProps {
  title: string | DefaultTFuncReturn;
  urls?: string[];
}

export const PageTitle = (props: PageTitleProps) => {
  const [showHelpDrawer, setShowHelpDrawer] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <YoutubeListDrawer
        urls={props.urls ?? []}
        open={showHelpDrawer}
        onClose={() => setShowHelpDrawer(false)}
      />

      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {props.title}
      </Typography>

      {!isEmpty(props.urls) && (
        <IconButton
          color='primary'
          size='small'
          onClick={() => setShowHelpDrawer(true)}
        >
          <HelpIcon />
        </IconButton>
      )}
    </Box>
  );
};
