import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ScheduleStatus } from '../../interfaces/Schedule';

interface ScheduleStatusDropdownProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  value: ScheduleStatus | null;
  onChange: (newValue: ScheduleStatus | null) => void;
}

const ScheduleStatusDropdown = (props: ScheduleStatusDropdownProps) => {
  const { t } = useTranslation();

  const options: ScheduleStatus[] = [
    ScheduleStatus.QUEUED,
    ScheduleStatus.RUNNING,
    ScheduleStatus.FINISHED,
    ScheduleStatus.REJECTED,
    ScheduleStatus.APPROVED,
  ];

  const handleChange = (e: any) => {
    const newValue = e.target.value as number;
    props.onChange(newValue < 0 ? null : options[newValue]);
  };

  return (
    <FormControl
      size='small'
      sx={{ width: props.width ?? 250, backgroundColor: props.backgroundColor }}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        value={props.value ? options.indexOf(props.value) : -1}
        label={props.label}
        disabled={props.disabled}
        onChange={handleChange}
        name='select'
        required
      >
        <MenuItem value={-1} key={-1}>
          {t('shared.all')}
        </MenuItem>
        {options.map((status, i) => (
          <MenuItem value={i} key={i}>
            {t(`schedule.status.${status}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ScheduleStatusDropdown;
