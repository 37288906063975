export enum PtoCategory {
  ANNUAL = 'ANNUAL',
  EXCUSED = 'EXCUSED',
  HAZARD = 'HAZARD',
  CONGRESS = 'CONGRESS',
  DUTY = 'DUTY',
}

export enum PtoSubtype {
  ANNUAL = 'ANNUAL',

  SPECIAL_REQUEST = 'SPECIAL_REQUEST',
  NON_PAID = 'NON_PAID',
  ON_DUTY = 'ON_DUTY',
  EXCUSED = 'EXCUSED',
  EDUCATIONAL = 'EDUCATIONAL',
  POST_BIRTH = 'POST_BIRTH',
  INSTITUTIONAL_INTERNAL = 'INSTITUTIONAL_INTERNAL',
  COURT = 'COURT',
  MEDICAL_RESCUE = 'MEDICAL_RESCUE',
  PAID_COMPANION = 'PAID_COMPANION',
  THIRTY_TWO_WEEKS = 'THIRTY_TWO_WEEKS',
  DEATH = 'DEATH',
  MARRIAGE = 'MARRIAGE',
  BIRTH = 'BIRTH',
  COMPANION = 'COMPANION',
  PATERNITY = 'PATERNITY',
  PRE_BIRTH = 'PRE_BIRTH',
  UNION = 'UNION',
  WEEKEND = 'WEEKEND',
  PART_TIME = 'PART_TIME',
  PARTIAL_TIME = 'PARTIAL_TIME',
  SEARCHING_FOR_JOB = 'SEARCHING_FOR_JOB',
  QUITTING_JOB = 'QUITTING_JOB',
  INSTITUTIONAL_TRANSFER = 'INSTITUTIONAL_TRANSFER',
  RADIOACTIVITY = 'RADIOACTIVITY',

  MEDICAL_COMMITTEE_REPORT = 'MEDICAL_COMMITTEE_REPORT',
  DISPATCH = 'DISPATCH',
  ACCOMPANIED = 'ACCOMPANIED',
  WITHOUT_REPORT = 'WITHOUT_REPORT',
  DOCTOR_REPORT = 'DOCTOR_REPORT',
  OCCUPATIONAL_HAZARD = 'OCCUPATIONAL_HAZARD',
  ON_DUTY_ASSAULT = 'ON_DUTY_ASSAULT',
  HOSPITALIZATION = 'HOSPITALIZATION',
  COVID_19_QUARANTINE = 'COVID_19_QUARANTINE',
  COVID_19_TREATMENT = 'COVID_19_TREATMENT',
  COVID_19_CONTACT = 'COVID_19_CONTACT',
  MEDICAL_INSPECTION = 'MEDICAL_INSPECTION',

  DOMESTIC_CONGRESS = 'DOMESTIC_CONGRESS',
  INTERNATIONAL_CONGRESS = 'INTERNATIONAL_CONGRESS',

  ARTICLE_THIRTY_NINE_NO_NOTIFICATION = 'ARTICLE_THIRTY_NINE_NO_NOTIFICATION',
  ARTICLE_THIRTY_NINE_DIRECTORIAL = 'ARTICLE_THIRTY_NINE_DIRECTORIAL',
  APPROVED_DUTY = 'APPROVED_DUTY',
  NON_APPROVED_DUTY = 'NON_APPROVED_DUTY',
}

export type PtoSubtypeByCategory = {
  [key in PtoCategory]: PtoSubtype[];
};
