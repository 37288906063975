import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty, isFinite } from 'lodash';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { getInstitutionTemplate } from '../../utils/Institution';
import { Institution } from '../../interfaces/Institution';
import {
  deleteInstitution,
  upsertInstitution,
} from '../../services/Institution';
import ConfirmDialog from '../ConfirmDialog';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import CityDropdown from '../City/CityDropdown';
import NumberInput from '../NumberInput';

interface UpsertInstitutionDialogProps {
  open: boolean;
  clickedInstitution?: Institution;
  onClose: () => void;
  onModify: () => void;
}

const UpsertInstitutionDialog = (props: UpsertInstitutionDialogProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(getInstitutionTemplate());
  const [clickedDelete, setClickedDelete] = useState(false);

  useEffect(() => {
    setCurrentState(
      props.clickedInstitution
        ? cloneDeep(props.clickedInstitution)
        : getInstitutionTemplate(),
    );

    if (!props.clickedInstitution) {
      setClickedDelete(false);
    }
  }, [props.clickedInstitution, props.open]);

  const handleConfirm = () => {
    setLoading(true);

    upsertInstitution(currentState)
      .then(() => {
        toast.success(
          props.clickedInstitution
            ? t('institution.successful.update')
            : t('institution.successful.add'),
        );

        props.onModify();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    if (!isFinite(props.clickedInstitution?.ID)) {
      return;
    }

    setLoading(true);
    deleteInstitution(props.clickedInstitution!.ID!)
      .then(() => {
        toast.success(t('institution.successful.delete'));
        setClickedDelete(false);
        props.onModify();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangeName = (e: any) => {
    setCurrentState({ ...currentState, name: e.target.value });
  };

  const handleChangeCity = (newCity: string) => {
    setCurrentState({ ...currentState, city: newCity });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <ConfirmDialog
        content={t('institution.delete.confirm_message')}
        open={clickedDelete}
        onClose={() => setClickedDelete(false)}
        onConfirm={handleDelete}
        color='error'
      />
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {props.clickedInstitution
          ? t('institution.dialog.update')
          : t('institution.dialog.add')}
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, width: 500 }}
      >
        <TextField
          label={t('institution.name')}
          value={currentState.name}
          onChange={handleChangeName}
          size='small'
          error={isEmpty(currentState.name)}
          sx={{ mt: 1.5 }}
          fullWidth
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          <CityDropdown
            label={t('institution.city') as string}
            width={250}
            required
            value={currentState.city}
            onChange={handleChangeCity}
          />

          <NumberInput
            label={t('institution.manager_approval_count') as string}
            value={currentState.managerApprovalCount}
            onChange={(x) =>
              setCurrentState({ ...currentState, managerApprovalCount: x })
            }
            error={currentState.managerApprovalCount < 0}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Button
          variant='contained'
          color='error'
          disabled={!props.clickedInstitution}
          onClick={() => setClickedDelete(true)}
          sx={sharedStyles.buttonText}
        >
          {t('shared.delete')}
        </Button>
        <Box component='div' sx={{ display: 'flex', flexGrow: 1 }} />
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            isEmpty(currentState.name) || currentState.managerApprovalCount < 0
          }
          onClick={handleConfirm}
          sx={sharedStyles.buttonText}
        >
          {props.clickedInstitution
            ? t('institution.dialog.update')
            : t('institution.dialog.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertInstitutionDialog;
