import { Shift } from '../interfaces/Shift';

export const defaultAlgorithmParameters = {
  non_fullday_night: 90000,
  fullday_night: -90000,
  worked_yesterday_night: 500000,
  worked_day_before_night: 30000,
  worked_yesterday_full_time: 700000,
  worked_day_before_full_time: 40000,
  consecutive_spare_days: 60000,
  overtime_hours: 15.5,
  missing_hours: -25,
  has_pto: -30,
  overtime_range_weight: 2800,
  overtime_stdev_weight: 2900,
  day_night_range_weight: 2500,
  day_night_stdev_weight: 2600,
  weekend_range_weight: 5000,
  weekend_stdev_weight: 5000,
  mutated_individual_count: 500,
  crossed_individual_count: 100,
  termination_steps: 350,
  elite_survivors: 170,
  newbie_survivors: 90,
  full_day_available_personnels: 400000,
};

export const shiftOptions = [
  Shift.EMPTY,
  Shift.DAY,
  Shift.NIGHT,
  Shift.ALL_DAY,
  Shift.EIGHT_HOUR_NIGHT,
  Shift.SIXTEEN_HOUR_DAY,
  Shift.SIXTEEN_TWENTY,
  Shift.EIGHT_TWENTY,
];

export const occupiedShifts = [Shift.DAY, Shift.NIGHT, Shift.ALL_DAY];
