import { Autocomplete, TextField } from '@mui/material';
import { DepartmentSection } from '../../interfaces/DepartmentSection';

interface DepartmentSectionAutocompleteProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  error?: boolean;
  options: DepartmentSection[];
  value: DepartmentSection | null;
  onChange: (newDepartment: DepartmentSection | null) => void;
}

const DepartmentSectionAutocomplete = (
  props: DepartmentSectionAutocompleteProps,
) => (
  <Autocomplete
    renderInput={(params) => (
      <TextField
        {...params}
        label={props.label}
        variant='outlined'
        size='small'
        error={props.error}
        disabled={props.disabled}
      />
    )}
    options={props.options}
    getOptionLabel={(departmentSection) => departmentSection.name}
    value={props.value}
    onChange={(_, newDepartmentSection) => props.onChange(newDepartmentSection)}
    disabled={props.disabled}
    sx={{
      width: props.width,
      minWidth: 200,
      maxWidth: 300,
      flex: '1 1 0',
      backgroundColor: props.backgroundColor,
    }}
  />
);

export default DepartmentSectionAutocomplete;
