import i18next from 'i18next';
import { DateTime } from 'luxon';
import {
  Schedule,
  ScheduleStats,
  ScheduleStatus,
  WorkedHours,
} from '../interfaces/Schedule';
import { getAllDatesBetweenTwoDate, isHoliday } from './Date';
import { getDepartmentTemplate } from './Department';
import { getDepartmentSectionTemplate } from './DepartmentSection';
import { getAuditTemplate } from './Audit';
import { FullDayDates } from '../interfaces/FullDayDates';
import { formatToLimitedDecimals } from './Decimals';
import { DateRange } from '../interfaces/DateRange';
import { Shift } from '../interfaces/Shift';
import { RotationType } from './enums/RotationType';
import { occupiedShifts } from '../variables/Schedule';
import { getDefaultAlgorithmParameterMultipliers } from '../interfaces/AlgorithmParameterMultipliers';

export const getScheduleStatsTemplate = (): ScheduleStats => ({
  totalPtoHours: 0,
  totalWorkedHours: 0,
  totalHoursShouldWorked: 0,
  totalOvertimeHours: 0,
});

export const getScheduleTemplate = (): Schedule => ({
  ID: 0,
  institutionID: 1,
  institutionName: '',
  department: getDepartmentTemplate(),
  departmentSection: getDepartmentSectionTemplate(),
  startAt: new Date(),
  endAt: new Date(),
  name: '',
  plan: {},
  reserveList: {},
  overtimeHours: {},
  sortedPersonnelNames: [],
  isModified: false,
  shiftHours: {},
  ptoSubtypes: {},
  shiftChanges: {},
  overtimeChanges: {},
  scheduleNotesCount: 0,
  requiredManagerApprovals: 0,
  managerApprovals: [],
  administrativeHours: 0,
  totalStats: getScheduleStatsTemplate(),
  status: ScheduleStatus.QUEUED,
  audit: getAuditTemplate(),
  fullDayDates: [],
  departmentSettings: {},
  weekendRotationType: RotationType.DAY_NIGHT,
  weekdayRotationType: RotationType.DAY_NIGHT,
  algorithmParameterMultipliers: getDefaultAlgorithmParameterMultipliers(),
  fourDPersonnelNames: [],
});

export const getScheduleDateRange = (schedule: Schedule): DateRange => ({
  startAt: schedule.startAt,
  endAt: schedule.endAt,
});

export const getOvertimeHours = (
  schedule: Schedule,
  personnelName: string,
  dateStr: string,
): number => {
  const personnelOvertimeHours = schedule.overtimeHours[personnelName] ?? {};
  return personnelOvertimeHours[dateStr] ?? 0;
};

export const hoursRangeStrFromShift = (
  shift: Shift,
  is4d: boolean,
  dayShiftHour: number,
): string => {
  switch (shift) {
    case Shift.DAY:
      return is4d ? `08.00-${dayShiftHour + 9}.00` : '08.00-16.00';
    case Shift.NIGHT:
      return '16.00-08.00';
    case Shift.ALL_DAY:
      return '08.00-08.00';
    case Shift.EIGHT_HOUR_NIGHT:
      return '16.00-00.00';
    case Shift.SIXTEEN_HOUR_DAY:
      return '08.00-00.00';
    case Shift.SIXTEEN_TWENTY:
      return '16.00-20.00';
    case Shift.EIGHT_TWENTY:
      return '08.00-20.00';
    default:
      return '';
  }
};

export const hoursStringFromShift = (
  schedule: Schedule,
  is4d: boolean,
  personnelName?: string,
  dateStr?: string,
  shift?: Shift,
): string => {
  const personnelShiftHours = personnelName
    ? schedule.shiftHours[personnelName]
    : undefined;
  const overtimeHours =
    personnelName && dateStr
      ? getOvertimeHours(schedule, personnelName, dateStr)
      : 0;
  if (
    personnelShiftHours &&
    shift &&
    personnelShiftHours[shift] &&
    shift !== Shift.EIGHT_HOUR_NIGHT &&
    shift !== Shift.SIXTEEN_HOUR_DAY &&
    shift !== Shift.SIXTEEN_TWENTY &&
    shift !== Shift.EIGHT_TWENTY
  ) {
    return add4dTimeOff(personnelShiftHours[shift] + overtimeHours, is4d);
  }

  switch (shift) {
    case Shift.DAY:
      return is4d
        ? `${formatToLimitedDecimals(10 + overtimeHours, 1)}*`
        : formatToLimitedDecimals(8 + overtimeHours, 1);
    case Shift.NIGHT:
      return is4d
        ? `${formatToLimitedDecimals(16 + overtimeHours, 1)}*`
        : formatToLimitedDecimals(16 + overtimeHours, 1);
    case Shift.ALL_DAY:
      return is4d
        ? `${formatToLimitedDecimals(24 + overtimeHours, 1)}*`
        : formatToLimitedDecimals(24 + overtimeHours, 1);
    case Shift.PTO:
      return i18next.t('schedule.shifts.pto');
    case Shift.ETO:
      return i18next.t('schedule.shifts.eto');
    case Shift.EIGHT_HOUR_NIGHT:
      return i18next.t('schedule.shifts.EIGHT_HOUR_NIGHT');
    case Shift.SIXTEEN_HOUR_DAY:
      return i18next.t('schedule.shifts.SIXTEEN_HOUR_DAY');
    case Shift.SIXTEEN_TWENTY:
      return i18next.t('schedule.shifts.SIXTEEN_TWENTY');
    case Shift.EIGHT_TWENTY:
      return i18next.t('schedule.shifts.EIGHT_TWENTY');
    default:
      return '\u00A0';
  }
};

export const hoursFromShift = (
  schedule: Schedule,
  personnelName: string,
  shift: Shift | undefined,
): number => {
  const personnelShiftHours = schedule.shiftHours[personnelName];
  if (personnelShiftHours && shift && personnelShiftHours[shift]) {
    return personnelShiftHours[shift];
  }

  switch (shift) {
    case Shift.DAY:
    case Shift.EIGHT_HOUR_NIGHT:
      return 8;
    case Shift.NIGHT:
    case Shift.SIXTEEN_HOUR_DAY:
      return 16;
    case Shift.ALL_DAY:
      return 24;
    default:
      return 0;
  }
};

export const getShiftOfPersonnel = (
  schedule: Schedule,
  personnelName: string,
  day: Date,
): Shift => {
  const dateStr = DateTime.fromJSDate(day).toISODate()!;
  const personnelPlan = schedule.plan[personnelName] ?? {};
  return personnelPlan[dateStr] ?? Shift.EMPTY;
};

export const getWorkedHours = (
  schedule: Schedule,
  personnelName: string,
  shifts: Record<string, Shift>,
  startAt: Date,
  endAt: Date,
  fullDays: FullDayDates[],
  fourDPersonnelNames: string[],
): WorkedHours => {
  const workedHours: WorkedHours = {
    totalWorkedHours: schedule.administrativeHours,
    totalHoursShouldWorked: schedule.administrativeHours,
    totalOvertimeHours: 0,
  };

  const isFourD: boolean = fourDPersonnelNames.includes(personnelName);

  getAllDatesBetweenTwoDate(startAt, endAt).forEach((date) => {
    if (!isHoliday(date, fullDays, isFourD)) {
      workedHours.totalHoursShouldWorked += isFourD
        ? hoursFromShift(schedule, personnelName, Shift.DAY_SHIFT_HOURS) === 0
          ? 7.5
          : hoursFromShift(schedule, personnelName, Shift.DAY_SHIFT_HOURS)
        : hoursFromShift(schedule, personnelName, Shift.DAY);
    }

    const dateStr = DateTime.fromJSDate(date).toISODate()!;
    const shift: Shift = shifts[dateStr];
    if (occupiedShifts.includes(shift)) {
      workedHours.totalWorkedHours += getOvertimeHours(
        schedule,
        personnelName,
        dateStr,
      );
    }

    workedHours.totalWorkedHours += hoursFromShift(
      schedule,
      personnelName,
      shift,
    );

    if (shift === Shift.PTO && !isHoliday(date, fullDays, isFourD)) {
      // eslint-disable-next-line no-nested-ternary
      workedHours.totalHoursShouldWorked -= isFourD
        ? hoursFromShift(schedule, personnelName, Shift.DAY_SHIFT_HOURS) === 0
          ? 7.5
          : hoursFromShift(schedule, personnelName, Shift.DAY_SHIFT_HOURS)
        : hoursFromShift(schedule, personnelName, Shift.DAY);
    }
  });

  workedHours.totalOvertimeHours =
    workedHours.totalWorkedHours - workedHours.totalHoursShouldWorked;

  return workedHours;
};

export const getReserveWorkedHours = (
  schedule: Schedule,
  personnelName: string,
  personnelPlan: Record<string, Shift>,
  dates: Date[],
): number =>
  dates.reduce((acc, date) => {
    const dateStr = DateTime.fromJSDate(date).toISODate()!;
    const shift: Shift = personnelPlan[dateStr];

    return acc + hoursFromShift(schedule, personnelName, shift);
  }, 0);

export const add4dTimeOff = (rawHours: number, is4d: boolean): string => {
  if (!is4d) return formatToLimitedDecimals(rawHours, 2);
  return `${formatToLimitedDecimals(
    rawHours > 7.5
      ? rawHours + 1
      : rawHours > 4
      ? rawHours + 0.5
      : rawHours + 0.25,
    2,
  )}*`;
};

export const getWorkedHoursByDate = (
  schedule: Schedule,
): Record<string, number> => {
  const workedHoursMap: Record<string, number> = {};

  const personnelNames = Object.keys(schedule.plan);

  getAllDatesBetweenTwoDate(schedule.startAt, schedule.endAt).forEach(
    (date) => {
      const dateStr = DateTime.fromJSDate(date).toISODate()!;
      let totalHours: number = 0;

      personnelNames.forEach((personnelName) => {
        const shiftsOfPersonnel = schedule.plan[personnelName];

        const shift = shiftsOfPersonnel[dateStr];
        if (shift) {
          totalHours +=
            hoursFromShift(schedule, personnelName, shift) +
            getOvertimeHours(schedule, personnelName, dateStr);
        }
      });

      workedHoursMap[dateStr] = totalHours;
    },
  );

  return workedHoursMap;
};

export const getReserveHoursByDate = (
  schedule: Schedule,
): Record<string, number> => {
  const workedHoursMap: Record<string, number> = {};

  const personnelNames = Object.keys(schedule.reserveList);

  getAllDatesBetweenTwoDate(schedule.startAt, schedule.endAt).forEach(
    (date) => {
      const dateStr = DateTime.fromJSDate(date).toISODate()!;
      let totalHours: number = 0;

      personnelNames.forEach((personnelName) => {
        const shiftsOfPersonnel = schedule.reserveList[personnelName];

        const shift = shiftsOfPersonnel[dateStr];
        if (shift) {
          totalHours += hoursFromShift(schedule, personnelName, shift);
        }
      });

      workedHoursMap[dateStr] = totalHours;
    },
  );

  return workedHoursMap;
};

export const compareSchedulePersonnelNames = (
  name1: string,
  name2: string,
  schedule: Schedule,
): number => {
  const i1 = schedule.sortedPersonnelNames.indexOf(name1);
  const i2 = schedule.sortedPersonnelNames.indexOf(name2);

  if (i1 < 0) {
    return 1;
  }
  if (i2 < 0) {
    return -1;
  }

  return i1 - i2;
};
