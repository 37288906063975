import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isFinite } from 'lodash';
import React, { useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import { Schedule } from '../../interfaces/Schedule';
import { isHoliday, isWorkday } from '../../utils/Date';
import { Shift } from '../../interfaces/Shift';
import {
  compareSchedulePersonnelNames,
  getReserveHoursByDate,
  getReserveWorkedHours,
  hoursRangeStrFromShift,
  hoursStringFromShift,
} from '../../utils/Schedule';
import { shiftOptions } from '../../variables/Schedule';
import { sharedColors, sharedStyles } from '../../utils/Style';

interface ReserveNameCellProps {
  schedule: Schedule;
  editable: boolean;
  onChange: (oldName: string, newName: string) => void;
  params: GridRenderCellParams;
}

const ReserveNameCell = (props: ReserveNameCellProps) => {
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    props.onChange(props.params.value, e.target.value);
  };

  const options = Object.keys(props.schedule.plan).filter(
    (name) => !Object.keys(props.schedule.reserveList).includes(name),
  );
  if (props.params.value) {
    options.push(props.params.value);
  }

  return props.editable && props.params.value !== t('shared.total') ? (
    <Box
      component='div'
      sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}
    >
      <IconButton
        size='small'
        color='error'
        disabled={!props.params.value}
        onClick={() => props.onChange(props.params.value, '')}
      >
        <CancelIcon />
      </IconButton>

      <FormControl size='small' fullWidth>
        <Select value={props.params.value || null} onChange={handleChange}>
          {options.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  ) : (
    <Typography>{props.params.value}</Typography>
  );
};

interface ReserveGridCellProps {
  dateStr: string;
  schedule: Schedule;
  params: GridRenderCellParams;
  is4d: boolean;
}

const ReserveGridCell = (props: ReserveGridCellProps) => {
  const { t } = useTranslation();

  let valueToDisplay: string;
  if (isFinite(props.params.value)) {
    valueToDisplay = props.params.value;
  } else if (shiftOptions.includes(props.params.value) || !props.params.value) {
    valueToDisplay = hoursStringFromShift(
      props.schedule,
      props.is4d,
      props.params.id as string,
      props.dateStr,
      props.params.value,
    );
  } else {
    valueToDisplay = t(`pto_subtype.abbreviation.${props.params.value}`);
  }

  const isConflict =
    !!props.params.value &&
    !!props.schedule.plan[props.params.row.name]?.[props.dateStr];

  return (
    <Box
      component='div'
      sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, gap: 0.5 }}
    >
      <Typography sx={{ mt: 'auto', mb: 'auto' }}>{valueToDisplay}</Typography>

      {isConflict && (
        <Tooltip title={t('schedule.warning.reserve_conflict')}>
          <WarningIcon
            fontSize='small'
            sx={{
              color: sharedColors.statusYellow,
              mt: 'auto',
              mb: 'auto',
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

interface ReserveListProps {
  schedule: Schedule;
  onChange: (newSchedule: Schedule) => void;
  fourDPersonnelNames: string[];
  dates: Date[];
  readOnly: boolean;
  isExpired: boolean;
}

export const ReserveList = (props: ReserveListProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const headerInner = document.querySelector(
        '.MuiDataGrid-columnHeadersInner',
      );
      const firstHeader = document.querySelector(
        '.MuiDataGrid-columnHeader[data-field="name"]',
      );

      if (!headerInner || !firstHeader) return () => {};

      const observer = new MutationObserver(() => {
        // @ts-ignore
        const match = headerInner.style.transform.match(
          /translate3d\((-?\d+)px, 0px, 0px\)/,
        );
        if (match) {
          const offsetX = parseInt(match[1], 10);
          // @ts-ignore
          firstHeader.style.transform = `translateX(${-offsetX}px)`;
        }
      });

      observer.observe(headerInner, {
        attributes: true,
        attributeFilter: ['style'],
      });

      return () => observer.disconnect();
    }, 300);

    return () => clearTimeout(timeout);
  }, []);

  const getColumnColorForDay = (day: Date): string => {
    if (!isWorkday(day)) return 'gray3';

    return isHoliday(day, props.schedule.fullDayDates) ? 'gray4' : 'gray1';
  };

  const handleChangeName = (oldName: string, newName: string) => {
    const reserveList = cloneDeep(props.schedule.reserveList);

    if (oldName && !newName) {
      delete reserveList[oldName];
    } else if (!oldName && newName) {
      reserveList[newName] = {};
    } else {
      reserveList[newName] = reserveList[oldName];
      delete reserveList[oldName];
    }

    props.onChange({ ...props.schedule, reserveList });
  };

  const datesColumns: GridColDef[] = props.dates.map((dateDay) => {
    const dateStr = DateTime.fromJSDate(dateDay).toISODate()!;

    return {
      field: dateStr,
      headerName: dateDay.toLocaleDateString('en-GB'),
      flex: 1,
      editable: !props.readOnly,
      minWidth: 100,
      sortable: false,
      type: 'singleSelect',
      valueOptions: (params: GridRenderCellParams) =>
        [
          Shift.EMPTY,
          Shift.DAY,
          Shift.NIGHT,
          Shift.ALL_DAY,
          Shift.EIGHT_HOUR_NIGHT,
          Shift.SIXTEEN_HOUR_DAY,
        ].map((shift) => {
          const is4d =
            props.fourDPersonnelNames.includes(params.id as string) ?? false;

          const dayShiftHour = props.schedule?.shiftHours[params.id as string]
            ? props.schedule?.shiftHours[params.id as string].DAY
            : 9;

          const hoursRange = hoursRangeStrFromShift(shift, is4d, dayShiftHour);
          const hoursRangeStr = hoursRange ? `[${hoursRange}]` : '';

          return {
            value: shift,
            label: `${hoursStringFromShift(
              props.schedule,
              is4d,
              params.id as string,
              undefined,
              shift,
            )} ${hoursRangeStr}`,
          };
        }),
      renderCell: (params: GridRenderCellParams) => (
        <ReserveGridCell
          dateStr={dateStr}
          schedule={props.schedule}
          params={params}
          is4d={props.fourDPersonnelNames.includes(params.id as string)}
        />
      ),
      cellClassName: () => getColumnColorForDay(dateDay),
      headerClassName: () => getColumnColorForDay(dateDay),
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('personnel.name')!,
      flex: 1,
      editable: false,
      sortable: false,
      minWidth: 270,
      cellClassName: () => 'sticky-column',
      renderCell: (params: GridRenderCellParams) => (
        <ReserveNameCell
          schedule={props.schedule}
          editable={!props.readOnly && !props.isExpired}
          onChange={handleChangeName}
          params={params}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Statü',
      flex: 1,
      editable: false,
      sortable: false,
      minWidth: 90,
    },
    ...datesColumns,
    {
      field: 'totalWorkedHours',
      headerName: t('schedule.total_hours') as string,
      flex: 1,
      editable: false,
      sortable: false,
      minWidth: 150,
    },
  ];

  const rows = Object.keys(props.schedule.reserveList)
    .sort((n1, n2) => compareSchedulePersonnelNames(n1, n2, props.schedule))
    .map((name) => {
      const personnelPlan = props.schedule.reserveList[name];

      const totalHours = getReserveWorkedHours(
        props.schedule,
        name,
        personnelPlan,
        props.dates,
      );

      const row: Record<string, string | number> = {
        id: name,
        status: props.fourDPersonnelNames.includes(name) ? '4D' : 'Normal',
        name,
        totalWorkedHours: totalHours,
      };

      props.dates.forEach((date: Date) => {
        const dateStr = DateTime.fromJSDate(date).toISODate()!;

        row[dateStr] = personnelPlan[dateStr];
      });

      return row;
    });

  const totalHoursByDate = getReserveHoursByDate(props.schedule);
  const totalHoursRow: Record<string, string | number> = {
    id: t('shared.total') as string,
    name: t('shared.total') as string,
    totalWorkedHours: Object.values(totalHoursByDate).reduce(
      (acc, x) => acc + x,
      0,
    ),
  };
  Object.keys(totalHoursByDate).forEach((date: string) => {
    totalHoursRow[date] = totalHoursByDate[date];
  });

  if (!props.readOnly && !props.isExpired) {
    rows.push({ id: '', name: '' });
  }
  rows.push(totalHoursRow);

  const onRowEditCommit = (newRow: any, oldRow: any) => {
    let changedDate: string = '';
    Object.keys(newRow).forEach((key: string) => {
      if (newRow[key] !== oldRow[key]) {
        changedDate = key;
        return true;
      }
      return false;
    });

    const reserveList = cloneDeep(props.schedule.reserveList);

    const shift = newRow[changedDate];
    if (shift === Shift.EMPTY) {
      delete reserveList[newRow.name][changedDate];
    } else {
      reserveList[newRow.name][changedDate] = shift;
    }

    const personnelPlan = reserveList[newRow.name];

    const totalHours = getReserveWorkedHours(
      props.schedule,
      newRow.name,
      personnelPlan,
      props.dates,
    );

    const result = cloneDeep(newRow);
    result.totalWorkedHours = totalHours;

    props.onChange({ ...props.schedule, reserveList });
    return result;
  };

  return (
    <Box
      component='div'
      sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
    >
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <DataGrid
          sx={{
            '& .gray1': {
              backgroundColor: sharedColors.gray1,
            },
            '& .gray3': {
              backgroundColor: sharedColors.gray3,
            },
            '& .gray4': {
              backgroundColor: sharedColors.gray4,
            },
            '& .MuiDataGrid-columnHeader[data-field="name"]': {
              position: 'sticky',
              left: 0,
              zIndex: 1002,
              backgroundColor: 'white',
            },
            '& .sticky-column': {
              position: 'sticky',
              left: 0,
              backgroundColor: 'white',
              zIndex: 1000,
            },
          }}
          disableVirtualization
          columns={columns}
          rows={rows}
          editMode='cell'
          hideFooter
          disableColumnMenu
          showCellVerticalBorder
          showColumnVerticalBorder
          isCellEditable={(params) =>
            params.row.id !== t('shared.total') && !props.isExpired
          }
          processRowUpdate={onRowEditCommit}
        />
      </TableContainer>
    </Box>
  );
};
