import { GridRenderCellParams } from '@mui/x-data-grid';
import { flatten, isEmpty, isFinite } from 'lodash';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import WarningIcon from '@mui/icons-material/Warning';
import HistoryIcon from '@mui/icons-material/History';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Schedule } from '../../interfaces/Schedule';
import { Shift } from '../../interfaces/Shift';
import { occupiedShifts, shiftOptions } from '../../variables/Schedule';
import {
  getOvertimeHours,
  getShiftOfPersonnel,
  hoursFromShift,
  hoursStringFromShift,
} from '../../utils/Schedule';
import { getNextDay } from '../../utils/Date';
import { sharedColors } from '../../utils/Style';
import ShiftChangeTooltip from './ShiftChangeTooltip';

interface ScheduleGridCellProps {
  dateDay: Date;
  dateStr: string;
  schedule: Schedule;
  personnelCountWarnings: string[];
  editable: boolean;
  params: GridRenderCellParams;
  is4d: boolean;
  onOvertimeHoursChange: (increment: number) => void;
}

const ScheduleGridCell = (props: ScheduleGridCellProps) => {
  const { t } = useTranslation();

  let valueToDisplay: string;
  let currentShift = Shift.EMPTY;
  if (isFinite(props.params.value)) {
    valueToDisplay = props.params.value;
  } else if (shiftOptions.includes(props.params.value) || !props.params.value) {
    valueToDisplay = hoursStringFromShift(
      props.schedule,
      props.is4d,
      props.params.id as string,
      props.dateStr,
      props.params.value,
    );
    currentShift = (props.params.value ?? Shift.EMPTY) as Shift;
  } else {
    valueToDisplay = t(`pto_subtype.abbreviation.${props.params.value}`);
  }

  const shiftChangeNotes =
    (props.schedule.shiftChanges[props.params.id] ?? {})[props.dateStr] ?? [];
  const overtimeChangeNotes =
    (props.schedule.overtimeChanges[props.params.id] ?? {})[props.dateStr] ??
    [];

  const nextShift = getShiftOfPersonnel(
    props.schedule,
    props.params.id as string,
    getNextDay(props.dateDay),
  );

  const isTotalRow = props.params.id === t('shared.total');
  const violatesAllDay =
    currentShift === Shift.ALL_DAY && occupiedShifts.includes(nextShift);
  const violatesNight =
    currentShift === Shift.NIGHT && occupiedShifts.includes(nextShift);

  const isFocused = props.params.hasFocus && props.editable;
  const overtimeHours = getOvertimeHours(
    props.schedule,
    props.params.id as string,
    props.dateStr,
  );

  return (
    <Box
      component='div'
      sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}
    >
      <Typography sx={{ mt: 'auto', mb: 'auto' }}>{valueToDisplay}</Typography>
      <Box component='div' sx={{ display: 'flex', flexGrow: 1 }} />
      {violatesAllDay && !isFocused && (
        <Tooltip title={t('schedule.warning.all_day')}>
          <WarningIcon
            fontSize='small'
            sx={{ color: sharedColors.statusRed, mt: 'auto', mb: 'auto' }}
          />
        </Tooltip>
      )}
      {violatesNight && !isFocused && (
        <Tooltip title={t('schedule.warning.night')}>
          <WarningIcon
            fontSize='small'
            sx={{
              color: sharedColors.statusYellow,
              mt: 'auto',
              mb: 'auto',
            }}
          />
        </Tooltip>
      )}
      {(!isEmpty(shiftChangeNotes) || !isEmpty(overtimeChangeNotes)) &&
        !isFocused && (
          <ShiftChangeTooltip
            shiftChangeNotes={shiftChangeNotes}
            overtimeChangeNotes={overtimeChangeNotes}
          >
            <HistoryIcon
              fontSize='small'
              color='primary'
              sx={{ mt: 'auto', mb: 'auto' }}
            />
          </ShiftChangeTooltip>
        )}
      {isTotalRow && !isEmpty(props.personnelCountWarnings) && (
        <Tooltip
          title={
            <Typography>
              {flatten(
                props.personnelCountWarnings.map((row, i) => [
                  row,
                  ...(i === props.personnelCountWarnings.length - 1
                    ? []
                    : [<br />, <br />]),
                ]),
              )}
            </Typography>
          }
        >
          <WarningIcon
            fontSize='small'
            sx={{ color: sharedColors.statusRed, mt: 'auto', mb: 'auto' }}
          />
        </Tooltip>
      )}
      {currentShift !== Shift.EMPTY && isFocused && (
        <Box
          component='div'
          sx={{
            mt: 'auto',
            mb: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton
            size='small'
            onClick={() => props.onOvertimeHoursChange(0.5)}
            sx={{ p: 0, minHeight: 0 }}
          >
            <ArrowDropUpIcon sx={{ mt: 0.5, mb: -0.5, p: 0, minHeight: 0 }} />
          </IconButton>
          <IconButton
            size='small'
            disabled={
              hoursFromShift(
                props.schedule,
                props.params.id as string,
                currentShift,
              ) +
                overtimeHours <=
              0
            }
            onClick={() => props.onOvertimeHoursChange(-0.5)}
            sx={{ mt: -0.5, mb: 0.5, p: 0, minHeight: 0 }}
          >
            <ArrowDropDownIcon sx={{ p: 0, minHeight: 0 }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ScheduleGridCell;
