import { Box, Chip, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { Schedule, ScheduleStatus } from '../../interfaces/Schedule';
import { sharedColors, sharedStyles } from '../../utils/Style';

interface ManagerApprovalsFieldProps {
  schedule: Schedule;
}

export const ManagerApprovalsField = (props: ManagerApprovalsFieldProps) => {
  if (props.schedule.status !== ScheduleStatus.APPROVED) {
    return null;
  }

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (isEmpty(props.schedule.managerApprovals)) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isComplete =
    props.schedule.requiredManagerApprovals <=
    props.schedule.managerApprovals.length;

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(ev) => ev.stopPropagation()}
    >
      <Chip
        label={
          <Typography
            sx={{
              ...sharedStyles.tableStringField,
              width: '100%',
              justifyContent: 'center',
              color: sharedColors.white,
            }}
          >
            {`${props.schedule.managerApprovals.length}/${props.schedule.requiredManagerApprovals}`}
          </Typography>
        }
        sx={{
          backgroundColor: isComplete
            ? sharedColors.statusGreen
            : sharedColors.statusOrange,
        }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ pointerEvents: 'none' }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, p: 1.5 }}
        >
          {props.schedule.managerApprovals.map((approval) => (
            <Box
              key={approval.id}
              sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
            >
              <CheckCircleIcon color='success' />

              <Typography>{approval.audit.createdBy}</Typography>
            </Box>
          ))}

          {Array.from(
            {
              length:
                props.schedule.requiredManagerApprovals -
                props.schedule.managerApprovals.length,
            },
            (_, i) => (
              <Box
                key={i}
                sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
              >
                <CircleIcon sx={{ color: sharedColors.gray3 }} />

                <Typography>...</Typography>
              </Box>
            ),
          )}
        </Box>
      </Popover>
    </Box>
  );
};
