import { Audit, auditFromResponse } from './Audit';

export interface ManagerApproval {
  id: number;
  scheduleID: number;
  audit: Audit;
}

export const managerApprovalFromResponse = (
  responseData: any,
): ManagerApproval => ({
  id: responseData.id,
  scheduleID: responseData.schedule_id,
  audit: auditFromResponse(responseData.audit),
});
