import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Button, Paper, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import { toast } from 'material-react-toastify';
import { Schedule, ScheduleStatus } from '../../interfaces/Schedule';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { approveSchedule, rejectSchedule } from '../../services/Schedule';
import AddScheduleNotesDialog from './AddScheduleNotesDialog';
import Waiting from '../Waiting';

interface ScheduleApprovalCardProps {
  schedule: Schedule;
  onChange: () => void;
}

const ScheduleApprovalCard = (props: ScheduleApprovalCardProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [clickedApprove, setClickedApprove] = useState(false);
  const [clickedReject, setClickedReject] = useState(false);

  const isVisible = (): boolean => {
    switch (props.schedule.status) {
      case ScheduleStatus.REJECTED:
      case ScheduleStatus.FINISHED:
        return hasWriteAccess(tokenData, AccessLevel.DEPARTMENT);
      default:
        return false;
    }
  };

  const handleApprove = (notes: string, files: File[]) => {
    setClickedApprove(false);
    setLoading(true);
    approveSchedule(props.schedule.ID!, notes, files)
      .then(() => {
        toast.success(t('schedule.approval.approval_successful'));
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleReject = (notes: string, files: File[]) => {
    setClickedReject(false);
    setLoading(true);
    rejectSchedule(props.schedule.ID!, notes, files)
      .then(() => {
        toast.success(t('schedule.approval.reject_successful'));
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return isVisible() ? (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        height: 'fit-content',
        padding: 1,
        mt: 1.5,
        backgroundColor: sharedColors.blue1,
      }}
    >
      <Waiting open={loading} />
      <AddScheduleNotesDialog
        title={t('schedule.approval.approve_plan')}
        content={t('schedule.approval.confirm_approval')}
        open={clickedApprove}
        onClose={() => setClickedApprove(false)}
        onConfirm={handleApprove}
        required={false}
      />
      <AddScheduleNotesDialog
        title={t('schedule.approval.reject_plan')}
        content={t('schedule.approval.confirm_reject')}
        open={clickedReject}
        onClose={() => setClickedReject(false)}
        onConfirm={handleReject}
        required={false}
      />
      <InfoOutlinedIcon
        sx={{
          mt: 'auto',
          mb: 'auto',
          mr: 1,
          color: sharedColors.blue4,
        }}
      />
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '18px',
          color: sharedColors.blue6,
          mt: 'auto',
          mb: 'auto',
          mr: 2,
        }}
      >
        {t('schedule.approval.message', {
          access: t('schedule.approval.department_access'),
        })}
      </Typography>
      <Button
        color='primary'
        onClick={() => setClickedApprove(true)}
        sx={{
          ...sharedStyles.buttonText,
          fontSize: '12px',
          fontWeight: 'bold',
          mt: 'auto',
          mb: 'auto',
        }}
      >
        {t('schedule.approval.approve')}
      </Button>
      <Button
        color='error'
        disabled={props.schedule.status === ScheduleStatus.REJECTED}
        onClick={() => setClickedReject(true)}
        sx={{
          ...sharedStyles.buttonText,
          fontSize: '12px',
          fontWeight: 'bold',
          mt: 'auto',
          mb: 'auto',
        }}
      >
        {t('schedule.approval.reject')}
      </Button>
    </Paper>
  ) : (
    <></>
  );
};

export default ScheduleApprovalCard;
