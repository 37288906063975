import { Autocomplete, TextField } from '@mui/material';
import { Institution } from '../../interfaces/Institution';

interface InstitutionAutocompleteProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  options: Institution[];
  value: Institution | null;
  onChange: (newInstitution: Institution | null) => void;
  error?: boolean;
}

const InstitutionAutocomplete = (props: InstitutionAutocompleteProps) => (
  <Autocomplete
    renderInput={(params) => (
      <TextField
        {...params}
        label={props.label}
        variant='outlined'
        size='small'
        disabled={props.disabled}
        error={props.error}
      />
    )}
    options={props.options}
    getOptionLabel={(institution) => institution.name}
    value={props.value}
    onChange={(_, newInstitution) => props.onChange(newInstitution)}
    disabled={props.disabled}
    sx={{
      width: props.width,
      minWidth: 200,
      maxWidth: 300,
      flex: '1 1 0',
      backgroundColor: props.backgroundColor,
    }}
  />
);

export default InstitutionAutocomplete;
