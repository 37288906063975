import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Box, Skeleton, Typography } from '@mui/material';
import { YoutubeSummary } from '../../interfaces/Youtube';
import { getYoutubeSummaryByVideoID } from '../../services/Youtube';
import { sharedColors } from '../../utils/Style';
import { YoutubeDialog } from './YoutubeDialog';

interface YoutubeVideoPreviewProps {
  videoID: string;
}

export const YoutubeVideoPreview = (props: YoutubeVideoPreviewProps) => {
  const [summary, setSummary] = useState<YoutubeSummary | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    getYoutubeSummaryByVideoID(props.videoID)
      .then((s) => setSummary(s))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }, [props.videoID]);

  return (
    <>
      <YoutubeDialog
        videoID={props.videoID}
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />

      <Box
        onClick={() => setShowDialog(true)}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 300,
          gap: 1.5,
          alignItems: 'center',
          p: 1.5,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: sharedColors.blue2,
          },
        }}
      >
        {loading ? (
          <>
            <Skeleton variant='rectangular' height={70} width={90} />
            <Skeleton variant='rectangular' height={60} width={200} />
          </>
        ) : (
          <>
            <Box
              component='img'
              src={summary?.thumbnail ?? ''}
              alt={props.videoID}
              height={70}
              width={90}
            />
            <Typography fontWeight={600}>{summary?.title}</Typography>
          </>
        )}
      </Box>
    </>
  );
};
