export enum Shift {
  ALL_SHIFTS = 'ALL_SHIFTS',
  DAY = 'DAY',
  NIGHT = 'NIGHT',
  ALL_DAY = 'ALL_DAY',
  EIGHT_HOUR_NIGHT = 'EIGHT_HOUR_NIGHT',
  SIXTEEN_HOUR_DAY = 'SIXTEEN_HOUR_DAY',
  SIXTEEN_TWENTY = 'SIXTEEN_TWENTY',
  EIGHT_TWENTY = 'EIGHT_TWENTY',
  PTO = 'PTO',
  ETO = 'ETO',
  DAY_SHIFT_HOURS = 'DAY_SHIFT_HOURS',
  EMPTY = '',
}
