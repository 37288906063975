import { Box, Drawer } from '@mui/material';
import { extractYoutubeVideoID } from '../../utils/Youtube';
import { YoutubeVideoPreview } from './YoutubeVideoPreview';

interface YoutubeListDrawerProps {
  urls: string[];
  open: boolean;
  onClose: () => void;
}

export const YoutubeListDrawer = (props: YoutubeListDrawerProps) => {
  const videoIDs: string[] = props.urls
    .map((url) => extractYoutubeVideoID(url) || '')
    .filter((id) => !!id);

  return (
    <Drawer anchor='right' open={props.open} onClose={props.onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {videoIDs.map((videoID, i) => (
          <YoutubeVideoPreview key={i} videoID={videoID} />
        ))}
      </Box>
    </Drawer>
  );
};
