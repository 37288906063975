import React, { JSX, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppsIcon from '@mui/icons-material/Apps';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { appBarHeightAtom } from '../atoms/AppHeader';
import { selectedPageAtom } from '../atoms/Page';
import { sharedStyles } from '../utils/Style';
import { tokenDataAtom } from '../atoms/TokenData';
import { getAvailablePages } from '../variables/Page';
import ChangePasswordDialog from './User/ChangePasswordDialog';

const DummyScrollButton = () => <></>;

const AppHeader = () => {
  const { t } = useTranslation();

  const { keycloak } = useKeycloak();

  const navigate = useNavigate();

  const ref = useRef(null);

  const setAppBarHeight = useSetRecoilState(appBarHeightAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);
  const tokenData = useRecoilValue(tokenDataAtom);

  const [settingsPopoverAnchor, setSettingsPopoverAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);

  const showSettingsPopover = Boolean(settingsPopoverAnchor);
  const settingsPopoverId = showSettingsPopover
    ? 'settings-popover'
    : undefined;

  useEffect(() => {
    if (ref && ref.current) {
      // @ts-ignore
      setAppBarHeight(ref.current.clientHeight);
    }
  });

  const pages = getAvailablePages(tokenData);

  const handleNavigateMainPage = () => {
    navigate('/');
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedPage(pages[newValue].pageType);
    navigate(pages[newValue].path);
  };

  const handleShowSettingsPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSettingsPopoverAnchor(event.currentTarget);
  };

  const handleCloseSettingsPopover = () => {
    setSettingsPopoverAnchor(null);
  };

  const handleLogout = () => {
    keycloak.logout();
  };

  const generateTabs = (): JSX.Element[] =>
    pages
      .filter((page) => !page.disabled)
      .map((page) => (
        <Tab
          label={
            <Typography sx={sharedStyles.tabLabel}>{t(page.name)}</Typography>
          }
        />
      ));

  if (!keycloak.authenticated) {
    return <></>;
  }

  return (
    <Box component='div'>
      <ChangePasswordDialog
        open={showChangePasswordDialog}
        onClose={() => setShowChangePasswordDialog(false)}
      />
      <AppBar
        position='fixed'
        elevation={1}
        ref={ref}
        sx={{
          backgroundColor: '#FFFFFF',
          overflowX: 'auto',
        }}
      >
        <Box
          component='div'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            paddingRight: 3,
            paddingLeft: 1,
          }}
        >
          <IconButton
            sx={{
              minHeight: 0,
              mt: 'auto',
              mb: 0.5,
            }}
          >
            <AppsIcon />
          </IconButton>
          <Button
            onClick={handleNavigateMainPage}
            color='primary'
            sx={{
              ...sharedStyles.buttonText,
              ...sharedStyles.h6,
              p: 1.5,
              minHeight: 0,
              mt: 'auto',
              mb: 0.5,
              mr: 1,
            }}
          >
            <Box component='img' src='/plaizen.png' width={90} />
          </Button>
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexGrow: 1,
              marginBottom: 0,
              height: '100%',
            }}
          >
            <Tabs
              variant='scrollable'
              value={pages.findIndex((page) => page.pageType === selectedPage)}
              onChange={handleTabChange}
              ScrollButtonComponent={DummyScrollButton}
            >
              {generateTabs()}
            </Tabs>
          </Box>
          <Button
            startIcon={<AccountCircleIcon fontSize='large' />}
            size='large'
            onClick={handleShowSettingsPopover}
            sx={{ ...sharedStyles.buttonText, fontSize: 16 }}
          >
            {tokenData.username ?? t('app_header.guest')}
          </Button>
          <Popover
            id={settingsPopoverId}
            open={showSettingsPopover}
            anchorEl={settingsPopoverAnchor}
            onClose={handleCloseSettingsPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box
              component='div'
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                startIcon={<KeyIcon />}
                disabled={!keycloak.authenticated}
                onClick={() => setShowChangePasswordDialog(true)}
                sx={{
                  padding: 1.5,
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                }}
              >
                {t('user.change_password')}
              </Button>
              <Button
                startIcon={<LogoutIcon />}
                disabled={!keycloak.authenticated}
                onClick={handleLogout}
                sx={{
                  padding: 1.5,
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                }}
              >
                {t('app_header.settings.sign_out')}
              </Button>
            </Box>
          </Popover>
        </Box>
      </AppBar>
    </Box>
  );
};

export default AppHeader;
