import axios from 'axios';
import { YoutubeSummary } from '../interfaces/Youtube';

export const getYoutubeSummaryByVideoID = async (
  videoID: string,
): Promise<YoutubeSummary> => {
  const response = await axios.get(
    `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoID}&format=json`,
  );

  return {
    title: response.data.title,
    thumbnail: response.data.thumbnail_url,
  };
};
